import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 500.000000 500.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">
  

  <path d="M0 2500 l0 -2500 2500 0 2500 0 0 2500 0 2500 -2500 0 -2500 0 0
-2500z m2811 1099 c168 -80 289 -266 289 -445 0 -220 -152 -427 -358 -488 -29
-8 -92 -38 -140 -66 -48 -27 -91 -50 -95 -50 -4 0 -7 65 -7 144 l0 143 89 5
c107 6 165 31 228 95 142 146 95 391 -92 484 -49 24 -56 24 -341 27 l-291 3
-94 95 -94 95 415 -3 415 -3 76 -36z m-151 -385 c50 -45 48 -99 -5 -146 -17
-15 -42 -17 -187 -18 l-168 0 0 -303 c0 -228 -3 -307 -12 -319 -11 -13 -172
-108 -184 -108 -2 0 -4 207 -4 460 l0 460 265 0 265 0 30 -26z m-1532 -1151
c3 -19 -1 -23 -22 -23 l-26 0 0 -115 0 -115 -35 0 -35 0 0 115 0 115 -28 0
c-24 0 -28 4 -26 23 1 12 3 23 3 25 1 2 38 2 84 0 76 -3 82 -5 85 -25z m250
11 c18 -13 22 -24 22 -72 0 -31 -4 -64 -9 -71 -5 -8 -31 -17 -58 -20 l-48 -6
-3 -47 c-3 -45 -5 -48 -32 -48 -35 0 -36 1 -32 156 l3 124 67 0 c45 0 75 -5
90 -16z m271 0 c5 -5 13 -21 16 -36 7 -27 5 -28 -28 -28 -22 0 -37 6 -41 15
-10 27 -36 17 -36 -14 0 -26 4 -29 42 -34 24 -3 48 -14 56 -24 16 -21 11 -108
-6 -125 -20 -20 -109 -22 -137 -4 -37 24 -32 70 8 74 22 3 30 -1 34 -18 9 -33
37 -21 41 18 3 31 2 32 -37 32 -53 0 -71 20 -71 77 0 69 20 86 93 81 31 -2 61
-9 66 -14z m183 -96 c3 -105 4 -113 23 -113 18 0 20 8 25 110 l5 110 30 0 30
0 0 -122 c0 -148 -6 -156 -98 -150 -81 5 -86 14 -87 160 l0 117 35 0 34 0 3
-112z m368 99 c22 -11 25 -19 23 -62 -1 -28 -2 -54 -2 -60 0 -5 2 -33 5 -61 8
-68 -13 -84 -108 -84 l-68 0 0 133 c0 74 3 137 7 140 11 12 117 7 143 -6z
m459 -13 c12 -15 21 -37 21 -50 0 -21 -5 -24 -35 -24 -31 0 -35 3 -35 26 0 21
-4 25 -22 22 -22 -3 -23 -9 -26 -87 -2 -58 1 -87 9 -92 20 -12 39 2 39 28 0
21 4 24 33 21 30 -3 32 -5 29 -38 -3 -48 -26 -62 -89 -58 -79 7 -83 13 -83
138 0 129 5 136 102 139 28 1 41 -5 57 -25z m269 10 c20 -14 22 -23 22 -124 0
-101 -2 -110 -22 -124 -12 -9 -43 -16 -68 -16 -25 0 -56 7 -68 16 -20 14 -22
23 -22 117 0 131 9 147 83 147 31 0 62 -6 75 -16z m218 -58 c13 -38 26 -71 28
-74 3 -2 10 16 16 39 18 72 38 109 59 109 50 0 51 -4 51 -146 l0 -134 -30 0
-30 0 0 86 c0 93 -11 102 -30 24 -9 -37 -17 -50 -30 -50 -23 0 -33 15 -51 70
l-14 45 -3 -87 -3 -88 -29 0 -30 0 0 141 0 140 37 -3 c35 -3 37 -5 59 -72z
m510 -6 l33 -75 3 75 3 75 33 3 32 3 0 -141 0 -141 -32 3 c-30 3 -35 9 -58 65
-14 34 -28 65 -32 69 -5 4 -8 -25 -8 -64 l0 -72 -30 0 -30 0 0 133 c0 74 3
137 7 141 4 4 16 6 27 4 14 -2 29 -25 52 -78z m356 63 c10 -9 18 -29 18 -45 0
-24 -4 -28 -29 -28 -20 0 -30 6 -34 20 -3 12 -14 20 -26 20 -20 0 -21 -5 -21
-89 0 -89 9 -110 38 -92 14 8 8 64 -7 69 -16 5 -13 29 5 36 9 3 29 6 45 6 l29
0 0 -65 c0 -58 -3 -66 -25 -81 -29 -19 -118 -15 -138 5 -14 14 -22 155 -13
207 8 41 29 52 95 53 28 1 51 -5 63 -16z m-1612 -228 c0 -33 -2 -35 -35 -35
-33 0 -35 2 -35 35 0 33 2 35 35 35 33 0 35 -2 35 -35z m1065 3 c-1 -30 -4
-33 -33 -36 -31 -3 -32 -2 -32 32 0 34 2 36 33 36 30 0 33 -2 32 -32z m-305
-261 c0 -2 -11 -41 -24 -87 -14 -46 -23 -87 -20 -92 3 -4 1 -8 -5 -8 -21 0
-21 21 0 81 11 35 19 73 16 86 -3 17 0 23 14 23 11 0 19 -1 19 -3z m219 -34
c-23 -91 -42 -143 -51 -143 -6 0 -8 10 -4 23 3 12 13 52 23 90 10 37 23 67 29
67 9 0 10 -10 3 -37z m125 -40 c-13 -43 -24 -86 -24 -95 0 -20 -20 -25 -20 -5
-1 31 46 177 57 177 8 0 4 -24 -13 -77z m-2060 49 c-6 -4 -20 -42 -30 -84 -15
-63 -23 -78 -38 -78 -13 0 -16 4 -9 13 15 19 45 136 38 147 -4 6 6 10 22 10
15 0 23 -4 17 -8z m67 -2 c13 -7 19 -21 19 -43 0 -34 -33 -97 -50 -97 -5 0 -1
15 10 33 30 48 26 96 -10 110 -10 4 -11 6 -2 6 7 1 22 -4 33 -9z m1010 -98
c-12 -34 -21 -71 -21 -82 0 -11 -7 -20 -15 -20 -17 0 -14 20 12 94 7 21 13 44
13 52 0 7 5 25 12 41 l12 28 3 -26 c2 -15 -5 -54 -16 -87z m-761 85 c0 -8 -9
-39 -19 -69 -14 -41 -16 -59 -8 -67 8 -8 6 -11 -6 -11 -22 0 -22 26 -1 102 15
53 34 78 34 45z m460 -5 c0 -10 -4 -22 -9 -27 -4 -6 -13 -30 -19 -55 -8 -34
-8 -46 1 -52 8 -5 7 -8 -5 -8 -29 0 -30 18 -8 90 21 66 40 92 40 52z m470 -14
c-5 -18 -17 -61 -28 -95 -11 -34 -17 -64 -14 -67 3 -3 -5 -6 -18 -6 -16 0 -20
3 -13 13 9 11 36 101 48 160 3 15 12 27 19 27 11 0 12 -8 6 -32z m-1020 -2 c0
-2 -7 -23 -15 -47 -9 -24 -14 -52 -11 -61 2 -11 -1 -18 -10 -18 -17 0 -17 10
-2 77 7 30 18 53 25 53 7 0 13 -2 13 -4z m164 -27 c-9 -18 -16 -47 -17 -65 -1
-37 -12 -45 -22 -15 -7 22 1 46 27 84 26 36 32 34 12 -4z m57 -34 c-16 -66
-38 -91 -28 -32 14 78 20 97 32 97 9 0 8 -15 -4 -65z m229 53 c0 -29 -25 -118
-33 -118 -9 0 -7 22 9 98 6 30 24 46 24 20z m249 -25 c-22 -43 -25 -79 -6 -87
6 -3 3 -4 -8 -3 -29 4 -26 69 5 102 13 14 24 25 26 25 1 0 -7 -17 -17 -37z
m71 28 c0 -5 -7 -28 -15 -52 -9 -24 -13 -49 -11 -56 6 -15 -10 -18 -19 -3 -8
13 24 120 36 120 5 0 9 -4 9 -9z m155 -10 c12 -39 -38 -119 -67 -108 -9 4 -7
6 4 6 24 1 55 86 38 106 -10 12 -10 15 3 15 9 0 18 -9 22 -19z m150 0 c11 -36
-31 -111 -64 -111 -11 0 -10 4 6 15 25 17 46 74 37 98 -3 10 -1 17 4 17 6 0
13 -8 17 -19z m133 -10 c5 -31 -16 -79 -39 -92 -17 -9 -17 -6 2 26 14 23 20
46 17 65 -3 19 -1 29 6 27 6 -2 12 -14 14 -26z m82 3 c0 -37 -21 -104 -33
-104 -5 0 -7 13 -3 33 4 17 10 47 13 65 7 38 23 43 23 6z m51 3 c-13 -13 -26
-3 -16 12 3 6 11 8 17 5 6 -4 6 -10 -1 -17z m88 16 c-36 -9 -65 -104 -36 -117
6 -3 3 -5 -8 -3 -32 4 -32 57 0 96 15 17 34 30 43 30 14 -1 15 -2 1 -6z m188
-5 c-2 -7 -10 -37 -17 -65 -12 -48 -30 -72 -30 -41 0 38 26 118 38 118 8 0 12
-6 9 -12z m141 -25 c4 -35 -39 -94 -66 -92 -16 1 -16 2 0 6 22 6 51 69 45 99
-3 16 0 23 7 21 6 -2 12 -17 14 -34z m164 15 c-31 -35 -39 -78 -17 -95 18 -12
18 -13 -3 -13 -43 0 -41 62 3 106 30 30 46 32 17 2z m48 8 c0 -7 -8 -20 -17
-27 -15 -12 -16 -12 -10 4 4 10 7 23 7 28 0 5 5 9 10 9 6 0 10 -6 10 -14z
m-2044 -21 c-19 -29 -21 -81 -3 -88 6 -3 2 -6 -10 -6 -20 -1 -23 4 -23 33 0
19 9 45 21 60 26 33 37 34 15 1z m465 11 c-21 -25 -32 -78 -20 -93 9 -10 8
-13 -4 -13 -19 0 -30 31 -20 56 13 30 37 64 47 64 4 0 3 -6 -3 -14z m753 -11
c-10 -15 -15 -38 -12 -60 2 -22 0 -34 -6 -32 -30 10 -7 117 24 117 6 0 3 -11
-6 -25z m400 -6 c-9 -18 -14 -45 -12 -60 3 -20 0 -29 -9 -29 -21 0 -16 64 8
94 28 35 33 33 13 -5z m-704 -264 c0 -8 -4 -15 -10 -15 -5 0 -10 7 -10 15 0 8
5 15 10 15 6 0 10 -7 10 -15z m324 -7 c-13 -42 -33 -83 -34 -68 0 28 21 90 31
90 6 0 7 -9 3 -22z m-599 -40 c-4 -18 -15 -59 -26 -92 -10 -32 -16 -62 -13
-67 3 -5 -4 -9 -15 -9 -19 0 -19 1 4 73 13 39 26 84 29 100 4 15 11 27 17 27
7 0 8 -12 4 -32z m53 -41 c2 -28 -3 -42 -22 -62 -14 -14 -30 -25 -35 -25 -6 0
1 11 15 26 21 20 25 33 22 65 -2 24 0 38 7 36 5 -2 11 -20 13 -40z m85 31 c4
-12 -20 -108 -29 -117 -13 -14 -13 14 -1 56 8 26 12 54 10 61 -3 6 0 12 5 12
6 0 12 -6 15 -12z m57 -3 c0 -8 -6 -15 -14 -15 -17 0 -28 14 -19 24 12 12 33
6 33 -9z m64 6 c3 -5 -1 -28 -9 -52 -9 -24 -13 -49 -11 -56 3 -7 -1 -13 -9
-13 -19 0 -19 28 0 74 9 20 13 41 10 46 -4 6 -2 10 4 10 5 0 12 -4 15 -9z
m128 -23 c-9 -9 -12 -7 -12 12 0 19 3 21 12 12 9 -9 9 -15 0 -24z m114 -6
c-18 -16 -19 -16 -12 1 3 10 6 24 6 30 0 8 4 7 12 -1 10 -9 8 -16 -6 -30z
m-175 -18 c-15 -43 -15 -46 3 -60 16 -11 17 -14 4 -14 -25 0 -41 27 -33 57 5
23 31 63 40 63 2 0 -5 -21 -14 -46z m136 29 c-27 -31 -33 -74 -11 -90 18 -14
18 -14 -6 -11 -18 2 -26 10 -28 28 -3 28 31 90 49 90 8 0 6 -6 -4 -17z m101
-85 c-6 -18 -28 -21 -28 -4 0 9 7 16 16 16 9 0 14 -5 12 -12z"/>
<path d="M1287 2033 c-4 -3 -7 -21 -7 -40 0 -29 3 -33 25 -33 23 0 25 4 25 40
0 32 -4 40 -18 40 -10 0 -22 -3 -25 -7z"/>
<path d="M2118 2017 c-6 -33 0 -46 22 -40 28 7 28 63 1 63 -12 0 -20 -9 -23
-23z"/>
<path d="M2112 1895 c-1 -31 1 -35 23 -35 22 0 25 4 25 35 0 30 -3 35 -23 35
-20 0 -24 -5 -25 -35z"/>
<path d="M2840 1960 c0 -44 -1 -83 -2 -87 -2 -5 7 -9 20 -11 21 -3 22 -1 22
87 0 84 -1 91 -20 91 -18 0 -20 -7 -20 -80z"/>
</g>
</svg>




        
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}